import React, { useEffect, useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import Cards from "./Cards";
import { getDatabase, ref, onValue } from "firebase/database";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Me from "./Me";
import More from "./More";
import BlobShape from "./BlobShape";
import Outlook from "./microsoft-outlook";
import Pow from "./components/Pow";
import RSVP from "./components/RSVP";
import bday from "./bday.webp";
import { useMediaQuery } from "react-responsive";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import Celebrate from "./components/Celebrate";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD91qpNRgjnK7qz-ROJxzXZgJE9Wxxto9k",
  authDomain: "niels-bday.firebaseapp.com",
  databaseURL:
    "https://niels-bday-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "niels-bday",
  storageBucket: "niels-bday.appspot.com",
  messagingSenderId: "282081879931",
  appId: "1:282081879931:web:d9f1edaaba2a2a4161ffde",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
export const auth = getAuth(app);
function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [attendees, setAttendees] = useState<any>({});
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        setUserId(uid);
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    console.log("LOADDING...");
    onValue(
      ref(db, "/chrisser/"),
      (snapshot) => {
        const data = snapshot.val();
        console.log(data);

        setAttendees(data);
        setLoading(false);
      },
      (error) => {
        console.error(error);
      }
    );

    return () => {};
  }, []);
  console.log({ attendees, isTabletOrMobile });
  return (
    <div className="App">
      <Grid
        container
        sx={{ minHeight: "100vh", overflow: "hidden", position: "relative" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: isTabletOrMobile ? "100vh" : "inherit",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "end",
            position: "relative",
            background: `url(${bday})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
          }}
        >
          {isTabletOrMobile && <More />}
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: isTabletOrMobile ? "100vh" : "inherit",
            backgroundColor: "#FAFAD2",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {loading ? (
            <div>
              <CircularProgress sx={{ color: "#DAA520" }} size={80} />
            </div>
          ) : (
            <RSVP attendees={attendees} userId={userId} />
          )}
          {/* <Me /> */}
        </Grid>
      </Grid>

      {/* <Grid
        container
        sx={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "#f4f4f5",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <BlobShape />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <h1>
            <b style={{ fontSize: 50, color: "#333" }}>Skelbækgade 2</b> <br />
            <p style={{ color: "#333" }}>1717 København</p>
            <br />
            <p style={{ color: "#333" }}>(More info later...)</p>
          </h1>
        </Grid>
        <More />
      </Grid>
      <Grid item>
        <b style={{ fontSize: 80, color: "#333" }}>Who is coming?</b> <br />
        <b style={{ fontSize: 20, color: "#333" }}>
          (Click on yourself and swipe to RSVP)
        </b>{" "}
        <br />
        <b>
          Going: {going} - Not going: {notGoing} - No reply: {noReply}
        </b>
        <br />
        <div style={{ marginTop: 20 }} />
        <TextField
          sx={{ minWidth: 320 }}
          id="outlined-search"
          label="Don't want to scroll? Find yourself here"
          type="search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Grid>
      <Cards key="att-cards" attendees={filteredAttendees} />
      <div style={{ marginTop: 60 }} /> */}
    </div>
  );
}

export default App;
