import React from "react";
import pow from "./pow.json";
import { useLottie } from "lottie-react";

export default function Pow() {
  const options = {
    animationData: pow,
    loop: true,
  };

  const { View } = useLottie(options);

  return <>{View}</>;
}
