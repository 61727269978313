import React from "react";
import { motion } from "framer-motion";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
export default function More() {
  return (
    <motion.div
      style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
      animate={{
        translateY: [-100, 0, 20, 0],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    >
      <KeyboardArrowDownRoundedIcon
        style={{ fontSize: 200, color: "#FAFAD2" }}
      />
    </motion.div>
  );
}
