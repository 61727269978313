import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import { IconButton, Stack, TextField } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Outlook from "../microsoft-outlook";
import AppleIcon from "@mui/icons-material/Apple";
import { ref, set } from "@firebase/database";
import { auth, db } from "../App";
import Pow from "./Pow";
import chris from "../chris.webp";
import Sad from "./Sad";
import { useMediaQuery } from "react-responsive";
import { signInAnonymously } from "firebase/auth";

const address = "Rådhusstræde 13, 2. sal, 1466 København";
const date = "13. Januar 2024";

export default function RSVP({ attendees, userId }: any) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [value, setValue] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [currentId, setCurrentId] = React.useState("");
  const [state, setState] = React.useState(false);
  const currentAttendee = userId ? attendees?.[userId] : null;

  React.useEffect(() => {
    setValue(currentAttendee?.name || "");
    setEmail(currentAttendee?.email || "");
  }, [currentAttendee]);

  const onUpdate = async (going: boolean) => {
    try {
      if (!userId) {
        return alert("Something went wrong");
      }
      await set(ref(db, "/chrisser/" + userId), {
        name: value,
        email,
        going,
        updateTime: new Date().getTime(),
      });

      setState(false);
    } catch (error) {
      alert("Ooops something went wrong :(");
    }
  };

  const renderState = () => {
    if (currentAttendee?.going) {
      return (
        <div>
          <div
            style={{
              position: "absolute",
              top: isTabletOrMobile ? "4%" : "10%",
              left: 0,
              right: 0,
            }}
          >
            {/* <CalendarEvents /> */}
            <h1 style={{ color: "#DAA520" }}>
              Juuuuhuuuu, {currentAttendee?.name}!
            </h1>
            <h2 style={{ color: "#DAA520" }}>Jeg glæder mig til at se dig!</h2>
            <h3 style={{ color: "#DAA520" }}>{address}</h3>
            <h3 style={{ color: "#DAA520" }}>{date} - kl 20:00</h3>
            <h3 style={{ color: "#DAA520" }}>
              <span style={{ fontWeight: 900 }}>Dresscode:</span> find glitter,
              guld og sølv frem
            </h3>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: 0,
              pointerEvents: "none",
            }}
          >
            <Pow />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: isTabletOrMobile ? -250 : -120,
              transform: "rotate(-10deg)",
              left: 0,
              right: -180,
            }}
          >
            <img src={chris} width={300} alt="chris" />
          </div>
        </div>
      );
    }
    if (currentAttendee?.going === false) {
      return (
        <div>
          <div style={{ position: "absolute", top: "20%", left: 0, right: 0 }}>
            <h1 style={{ color: "#DAA520" }}>
              Øøøøøøøv, {currentAttendee?.name}!
            </h1>
            {/* <h1>Jeg glæder mig til at se dig!</h1> */}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Sad />
          </div>
          <div>
            <Button
              variant="contained"
              size="large"
              style={{ fontSize: 24, fontWeight: "bold" }}
              onClick={() => setState(true)}
            >
              Skift mening
            </Button>
          </div>
        </div>
      );
    }

    const onRSVP = async () => {
      try {
        if (userId) {
          setState(true);
          return;
        }
        await signInAnonymously(auth);
        setState(true);
      } catch (error) {
        alert("something went wrong");
        // ...
      }
    };

    return (
      <div>
        <h1>
          <span style={{ fontSize: 280, color: "#DAA520" }}>30</span> <br />
          <span style={{ color: "#DAA520" }}>års fødselsdag</span>
          <br />
          <span style={{ color: "#DAA520" }}>{date} - kl 20:00</span>
          <br />
        </h1>
        {/* <CalendarEvents /> */}
        <Button
          variant="contained"
          size="large"
          style={{ fontSize: 24, fontWeight: "bold" }}
          onClick={onRSVP}
        >
          RSVP
        </Button>
        <div
          style={{
            position: "absolute",
            right: -100,
            bottom: -260,
            transform: "rotate(-10deg)",
            zIndex: 0,
            pointerEvents: "none",
          }}
        >
          <img src={chris} width={300} alt="chris" />
        </div>
      </div>
    );
  };

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          {renderState()}
          <SwipeableDrawer
            anchor={anchor}
            open={state}
            onClose={() => setState(false)}
            onOpen={() => setState(true)}
          >
            <Box
              sx={{
                width: isTabletOrMobile ? "calc(100vw - 80px)" : 500,
                p: 4,
                backgroundColor: "#FAFAD2",
                height: "100vh",
              }}
              role="presentation"
              // onClick={toggleDrawer(anchor, false)}
              // onKeyDown={toggleDrawer(anchor, false)}
            >
              <Stack justifyContent="space-between">
                <Stack>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Dit fulde navn, tak 🙏"
                    variant="outlined"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <Box mt={2} />
                </Stack>
                <Stack>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Din email (så du kan få opdateringer)"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Box mt={2} />
                </Stack>
                {isTabletOrMobile && (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      disabled={!value || !email}
                      onClick={() => onUpdate(true)}
                    >
                      Kommer 🥳
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="large"
                      disabled={!value || !email}
                      onClick={() => onUpdate(false)}
                    >
                      Kommer ikke 🙈
                    </Button>
                  </Stack>
                )}
                {!isTabletOrMobile && (
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      size="large"
                      disabled={!value || !email}
                      onClick={() => onUpdate(false)}
                    >
                      Kommer ikke 🙈
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      disabled={!value || !email}
                      onClick={() => onUpdate(true)}
                    >
                      Kommer 🥳
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Box>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

function CalendarEvents() {
  return (
    <div
      style={{
        color: "#333",
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        justifyContent: "center",
      }}
    >
      {/* <a
              
              title="Save Event in my Calendar"
              style={{ color: "#333" }}
            > */}
      <IconButton
        target="_blank"
        href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240113T190000Z%2F20240114T010000Z&details=Get%20ready%20to%20get%20your%20glitter%20on&location=R%C3%A5dhusstr%C3%A6de%2013%2C%202.%20sal%2C%201466%20K%C3%B8benhavn&text=Chris%2030%20%C3%A5rs"
      >
        <GoogleIcon sx={{ color: "#DAA520" }} />
      </IconButton>
      {/* </a> */}

      {/* <a title="Save Event in my Calendar" style={{ color: "#333" }}> */}
      <IconButton
        target="_blank"
        href="https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=Get%20ready%20to%20get%20your%20glitter%20on&enddt=2024-01-14T01%3A00%3A00%2B01%3A00&location=R%C3%A5dhusstr%C3%A6de%2013%2C%202.%20sal%2C%201466%20K%C3%B8benhavn&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2024-01-13T20%3A00%3A00%2B01%3A00&subject=Chris%2030%20%C3%A5rs"
      >
        <Outlook />
      </IconButton>
      {/* </a> */}

      {/* <a
              href={}
              download
              title="Save Event in my Calendar"
              style={{ color: "#333" }}
            > */}
      <IconButton
        download
        href="https://storage.googleapis.com/niels-bday.appspot.com/chris.ics"
      >
        <AppleIcon sx={{ color: "#DAA520" }} />
      </IconButton>
      {/* </a> */}
    </div>
  );
}
