import React from "react";
import sad from "./sad.json";
import { useLottie } from "lottie-react";

export default function Sad() {
  const options = {
    animationData: sad,
    loop: true,
  };

  const { View } = useLottie(options);

  return <>{View}</>;
}
